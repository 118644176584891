<template>
  <div class="main_div">
    <div class="top_div">
      <span>{{ type.title }}</span>
      <div class="btn_div">
        <el-button class="btnAdd" type="primary" size="medium" @click="showAddDialog = true">新增{{type.title }}
        </el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <ShanghaiRSNTable ref="ShanghaiRSNTable" :table-headers="tableHeaders" @edit="edit" :typeVal="type"></ShanghaiRSNTable>
    <AddManifestContactDialog
        :is-show="showAddDialog"
        :item="editItem"
        @refresh="refreshList"
        @close="showAddDialog=false"
        :typeVal="type"></AddManifestContactDialog>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/contacts/contactsRSN'

export default {
  mixins: [mixin],

  data() {
    return {
      type: {
        title: '发货人',
        code: 0
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.main_div {
  background: white;
  padding: 1rem;
  height: 100%;
}

.top_div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 20px;
    font-weight: bold;
  }

  .btn_div {
    display: flex;
  }
}

.el-divider--horizontal {
  margin: 1rem 0;
}

</style>
